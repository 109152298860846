import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row } from "react-bootstrap"
import "aos/dist/aos.css"
import AOS from "aos"
import med1 from "../images/media/med1.png"
import med2 from "../images/media/med2.png"
import med3 from "../images/media/med3.png"
import med4 from "../images/media/med4.png"
import med5 from "../images/media/med5.png"
import med6 from "../images/media/med6.png"
import med7 from "../images/media/med7.png"
import med8 from "../images/media/med8.png"
import med9 from "../images/media/med9.png"
import med10 from "../images/media/med10.png"
import med11 from "../images/media/med11.jpg"
import med12 from "../images/media/med12.png"
import med13 from "../images/media/med13.png"
import med14 from "../images/media/med14.png"
import med15 from "../images/media/med15.png"
import med16 from "../images/media/med16.png"
import tfiAsset from "../images/tfi_assets.png"

import ps1 from "../images/media/ps1.jpg"
import ps2 from "../images/media/ps2.jpg"
import ps3 from "../images/media/ps3.jpg"
import ps5 from "../images/media/ps5.jpg"

import cdots1 from "../images/dots/media/1.png"
import cdots2 from "../images/dots/media/2.png"

import cbdots1 from "../images/dots/mediab/1.png"
import cbdots2 from "../images/dots/mediab/2.png"
import cbdots3 from "../images/dots/mediab/3.png"
import { Link } from "gatsby"

const media = () => {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])
  return (
    <Layout pageName="media">
      <SEO title="Media" />
      <Container className="posrel text-center my-5">
        <img
          src={cdots1}
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          className="color-patcher"
        />
        <img
          src={cdots2}
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-duration="1000"
          className="color-patcher"
        />
        <p className="wn-title">Media</p>
        <p className="fi-txt mb-0">For media inquiries, write to us at</p>
        <p>
          <a className="med-link" href="mailto:communication@teachforindia.org">
            communication@teachforindia.org
          </a>
        </p>
      </Container>
      <Container>
        <Row>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="http://indiamediamonitor.in/ViewImg.aspx?R6uYWcDC7x7SfKRiLn1oRlTWGw9CYTT5sKce6Q1IDDq4PnUqsVdBLlfO1SX9Pl/fPSCzbl5/oN13Wn8z4EYGLBuiKZ2xSO8ECJuc5VJhHpI="
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={med11} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">600 Days of Misery</p>
                  <p className="fi-txt fi-cont">Why Schools must remain open</p>
                  <p className="fi-txt date mb-0">4 December 2021</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="https://scopupmagazine.com/magazine/indias-revolutionary-ngos-2021/?digitalversion=true#1"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={med12} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">
                    India&apos;s 10 Revolutionary NGOs 2021
                  </p>
                  <p className="fi-txt fi-cont">
                    Teach For India: Fostering excellent education and creating
                    extraordinary leaders
                  </p>
                  <p className="fi-txt date mb-0">1 October 2021</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="https://www.indiatoday.in/education-today/how-i-made-it/story/how-teach-for-india-founder-shaheen-mistri-built-a-network-of-education-leaders-impacting-millions-1849504-2021-09-05"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={med13} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">India Today</p>
                  <p className="fi-txt fi-cont">
                    How Teach For India founder Shaheen Mistri built a network
                    of education leaders impacting millions
                  </p>
                  <p className="fi-txt date mb-0">5 September 2021</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="https://www.cnbctv18.com/india/get-schooled-learning-gaps-gaps-in-socialisation-skills-will-have-to-be-bridged-says-experts-10593351.htm"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={med14} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">CNBC TV 18</p>
                  <p className="fi-txt fi-cont">
                    Get Schooled: Learning gaps, gaps in socialisation skills
                    will have to be bridged, says experts
                  </p>
                  <p className="fi-txt date mb-0">2 September 2021</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="http://indiamediamonitor.in/ViewImg.aspx?coSgcnIOlWnSjCMUookiNO2XqanHy4WYzFTMpmRueqkMf4KO42vYdVjTtJfZIqYlEnYb+y48g5UNlwOuds9oGMlGgjUk5hxjNvQ1DHXZl8I="
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={med15} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">India Media Monitor</p>
                  <p className="fi-txt fi-cont">
                    Ensuring education for all children
                  </p>
                  <p className="fi-txt date mb-0">23 August 2021</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="https://m-partners.facebook.com/ndtv/videos/we-the-people-when-is-it-safe-to-reopen-schools/223739482922209/"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={med16} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">NDTV</p>
                  <p className="fi-txt fi-cont">
                    We The People | When Is It Safe To Reopen Schools?
                  </p>
                  <p className="fi-txt date mb-0">27 June 2021</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="https://www.livemint.com/companies/news/dell-foundation-invests-rs-22-crore-to-improve-learning-of-needy-students-11605000791769.html"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={med1} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">The Mint</p>
                  <p className="fi-txt fi-cont">
                    Dell foundation invests rs 22 crore to improve learning of
                    needy students
                  </p>
                  <p className="fi-txt date mb-0">10 November 2020</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="http://bweducation.businessworld.in/article/-When-We-Reimagine-Education-We-Must-Imagine-It-For-Everyone-Dr-Shashi-Tharoor-Shares-His-Vision-At-inspirED-2020/08-10-2020-329329/"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={med2} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">BW Education</p>
                  <p className="fi-txt fi-cont">
                    When We Reimagine Education, We Must Imagine It For
                    Everyone”, Dr Shashi Tharoor Shares His Vision At inspirED
                    2020
                  </p>
                  <p className="fi-txt date mb-0">8 October 2020</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="https://www.youtube.com/watch?v=hb3a1bq1EPQ"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={med8} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">CNBC - TV18</p>
                  <p className="fi-txt fi-cont">Teaching for a better India</p>
                  <p className="fi-txt date mb-0">5 October 2020</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="https://www.firstpost.com/india/how-teach-for-india-alumni-are-playing-a-role-in-nation-building-6268121.html"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={med3} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">First Post</p>
                  <p className="fi-txt fi-cont">
                    How Teach For India alumni are playing a role in
                    nation-building
                  </p>
                  <p className="fi-txt date mb-0">15 March 2019</p>
                </div>
              </div>
            </a>
          </div>

          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="https://www.thehindubusinessline.com/news/variety/teaching-for-a-better-india/article23031949.ece"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={med5} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">The Hindu Business Line</p>
                  <p className="fi-txt fi-cont">Teaching for a better India</p>
                  <p className="fi-txt date mb-0">19 January 2012</p>
                </div>
              </div>
            </a>
          </div>

          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="https://timesofindia.indiatimes.com/entertainment/events/mumbai/dia-mirza-teaches-kids-key-ideas-on-environment-protection/articleshow/72663354.cms"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={med10} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">Times of India</p>
                  <p className="fi-txt fi-cont">
                    Dia Mirza teaches kids key ideas on environment protection
                  </p>
                  <p className="fi-txt date mb-0">15 December 2019</p>
                </div>
              </div>
            </a>
          </div>
        </Row>
      </Container>

      <Container>
        <div className="text-center py-5">
          <p className="wn-title mt-5">Assets</p>
          <div className="max-60 max-sm-100 mx-auto">
            <img src={tfiAsset} className="w-100" />
          </div>
          <p className="pt-5">
            <a
              href="https://drive.google.com/drive/folders/1vsxzTbJjZcj9qQn41Myc4K8mnCaAn2p3?authuser=1"
              target="_blank"
              rel="noreferrer noopener"
              className="butn butn-main mr-2"
            >
              Download
            </a>
          </p>
        </div>
        <div className="boiler-plate my-5 posrel text-center">
          <img
            src={cbdots1}
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            className="color-patcher d-none d-lg-block"
          />
          <img
            src={cbdots2}
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
            className="color-patcher d-none d-lg-block"
          />
          <img
            src={cbdots3}
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-duration="1000"
            className="color-patcher d-none d-lg-block"
          />
          <p className="wn-title">Teach For India Overview</p>
          <div className="max-60 max-sm-100 mx-auto">
            <p className="fi-txt">
              Teach For India’s Fellowship places outstanding working
              professionals and college graduates as Fellows - who work
              full-time as teachers for two years in low-income and
              under-resourced schools. With operations in Mumbai, Delhi,
              Hyderabad, Chennai, Pune, Ahmedabad and Bangalore, Teach For India
              has over 900 Fellows impacting more than 32,000 children across
              the country. Post Fellowship, they join a growing movement of over
              3400+ Alumni working at all levels of the education sector.
            </p>
            <p className="fi-txt">
              A part of a global Teach For All network of 59 countries, with a
              shared vision of an excellent education for all children, Teach
              For India’s mission is to end educational inequity in India by
              creating a strong pipeline of driven, skilled talent within the
              educational ecosystem.
            </p>
            <p className="pt-5">
              <Link to="/our-people" className="butn butn-main mr-2">
                Our People
              </Link>
              <Link to="/faqs" className="butn butn-main">
                Media FAQs
              </Link>
            </p>
          </div>
        </div>
      </Container>
      <Container className="posrel text-center my-5 pt-5">
        <p className="wn-title">Latest Press Releases</p>
      </Container>
      <Container className="pb-5">
        <Row>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="https://docs.google.com/document/d/10ZItbOPGjzWEuLaxalsb-_fMFwRNd2HJcTEwuRyqrkk/edit"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={ps1} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">KER Week</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="https://docs.google.com/document/d/1nJsWxgooqB1iSQoLNRmRqhOErSAXjU26/edit?rtpof=true"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={ps2} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">
                    Fellowship Applications Open
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="https://docs.google.com/document/d/1xIeIcZbwJvm-VxCeJSKXZzqBdSE1a0SnTSpofzA1V5k/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={ps3} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">
                    Kautilya Panel Discussion
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="https://docs.google.com/document/d/1_wQbLxhZanH4mpcPnwOsXIswK1buIKZUqZuOo87h7pk/edit"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={ps2} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">Institute Opening </p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="https://docs.google.com/document/d/1RUcnbKXAqeTt0prJRdOL33vvEIrLAH_mf0j1NsfBLyA/edit"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={ps5} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">TFIx Cohort 2021</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6 py-3">
            <a
              href="https://docs.google.com/document/d/1Xc6PTOymkRQ8emJHousbXNYkaGEwcto89QAQQC26lrI/edit"
              target="_blank"
              rel="noopener noreferrer"
              className="media-link"
            >
              <div className="media-card">
                <img src={ps2} className="w-100" />
                <div className="content">
                  <p className="fi-txt mb-0 bolder">Hyderbad Research Study</p>
                </div>
              </div>
            </a>
          </div>
        </Row>
      </Container>
    </Layout>
  )
}

export default media
